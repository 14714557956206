import React, { useEffect, useState } from 'react';
import CarIcon from '@material-ui/icons/DirectionsCar';
import BuildIcon from '@material-ui/icons/Build';
import ConditionsIcon from '@material-ui/icons/AcUnit';
import PhoneIcon from '@material-ui/icons/Phone';
import ToolTalkIcon from '@material-ui/icons/BusinessCenter';
import CompanyHandbookIcon from '@material-ui/icons/ChromeReaderMode';
import SafetyHandbookIcon from '@material-ui/icons/Warning';
import AttendanceIcon from '@material-ui/icons/CheckCircle';
import PerDiemIcon from '@material-ui/icons/AttachMoney';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import SpeedIcon from '@material-ui/icons/Speed';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

import useBreakpoint from 'hooks/useBreakpoint.hook';

import { Typography, Grid, Paper } from 'helpers/themeSafeMui.helper';
import { ExternalLink, Toast } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useColors } from 'helpers/theme.helper';
import http from 'helpers/http.helper';
import TrainingCalendar from '../modals/TrainingCalendar.modal';

export default function DashboardLinks(props) {
  const user = useSelector(state => state.user);
  const colors = useColors();
  const [weeklyModuleId, setWeeklyModuleId] = useState('');

  const isSmScreen = useBreakpoint('sm');

  useEffect(() => {
    http()
      .get('/training-quizzes/weekly-module')
      .then(weeklyModule => {
        if (weeklyModule) setWeeklyModuleId(weeklyModule.trainingModuleId);
      })
      .catch(Toast.showErr);
  }, []);

  const dashboardLinks = [
    {
      title: 'Company Handbook',
      icon: (
        <CompanyHandbookIcon color="action" style={{ fontSize: '2.4em' }} />
      ),
      url:
        'https://docs.google.com/document/d/1oiaU2MJfcLs5Q01WkTIcPS24SfkJJhw95UJPfjUPvao/edit'
    },
    {
      title: 'Safety Manual',
      icon: <SafetyHandbookIcon color="action" style={{ fontSize: '2.4em' }} />,
      url:
        'https://docs.google.com/document/d/1BTucEm4FWKo6BYhNPRxPkfSFDsWny-d8Ezir1fyz87A/edit?usp=sharing'
    },
    {
      title: 'Tool Box Talk',
      icon: <ToolTalkIcon color="action" style={{ fontSize: '2.4em' }} />,
      ...(weeklyModuleId
        ? {
            internal: true,
            url: `/users/${user.id}/trainings?moduleId=${weeklyModuleId}`
          }
        : {
            url:
              'https://docs.google.com/forms/d/e/1FAIpQLSdftmPiOG8_QWcoYRQJ3LpynGNTVEINxP574K3kZZpInmd5nw/viewform'
          })
    },
    {
      title: 'Employee Recognition',
      url: 'https://app.smartsheet.com/b/form/13f9c94dc6be4f9cbbd58ab0ce25f804',
      icon: <EmojiEventsIcon color="action" style={{ fontSize: '2.4em' }} />
    },
    {
      element: <TrainingCalendar />,
      url: 'Training Calendar'
    },
    {
      title: 'Per Diem Request',
      icon: <PerDiemIcon color="action" style={{ fontSize: '2.4em' }} />,
      url: 'https://app.smartsheet.com/b/form/0195ce5c3b797766985431d8d2f8d56e'
    },
    {
      title: 'Expense Reimbursement',
      icon: <MonetizationOn style={{ fontSize: '2.4em' }} color="action" />,
      url: 'https://app.smartsheet.com/b/form/db0c140755a04e6fbb024dd26416a75c'
    },
    {
      title: 'Mileage Reimbursement',
      icon: <SpeedIcon style={{ fontSize: '2.4em' }} color="action" />,
      url: 'https://app.smartsheet.com/b/form/ad9625ec465f49199c9113748544d3ca'
    },
    {
      title: 'Road Conditions',
      icon: <ConditionsIcon style={{ fontSize: '2.4em' }} color="action" />,
      url: 'https://511.idaho.gov'
    },
    {
      title: 'Phone Login',
      icon: <PhoneIcon style={{ fontSize: '2.4em' }} color="action" />,
      url: 'https://voip.systemtech.cloud/login'
    },
    {
      title: 'Attendance Form',
      icon: <AttendanceIcon color="action" style={{ fontSize: '2.4em' }} />,
      url: 'https://app.smartsheet.com/b/form/fa4b642cf68e460ebc465141add48a2d'
    },
    {
      title: 'Vehicle & Tool Transfer',
      icon: <CarIcon color="action" style={{ fontSize: '2.4em' }} />,
      url: 'https://secure.fleetio.com/bab46ae781/dashboard'
    },
    {
      title: 'Personal Tool Purchase',
      icon: <BuildIcon color="action" style={{ fontSize: '2.4em' }} />,
      url: 'https://app.smartsheet.com/b/form/2d0ef8aa06ad4ac5aa8d52f9dd5a048e'
    }
  ];

  return (
    <Paper style={{ padding: 32 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: 10,
          cursor: 'pointer'
        }}
      >
        <Typography variant="h5">Dashboard Links</Typography>
      </div>

      <Grid
        container
        spacing={2}
        style={{
          textAlign: 'center',
          justifyContent: isSmScreen ? 'flex-start' : 'space-between'
        }}
      >
        {dashboardLinks.map(dashboardLink => (
          <Grid
            key={dashboardLink.url}
            item
            md={1}
            sm={2}
            xs={4}
            style={{ flexBasis: 0 }}
          >
            <Typography>
              {!!dashboardLink.element ? (
                dashboardLink.element
              ) : dashboardLink.internal ? (
                <RouterLink
                  onClick={e => e.stopPropagation()}
                  to={dashboardLink.url}
                  style={{ color: colors.primary }}
                >
                  {dashboardLink.icon}
                  <br />
                  <Typography variant="caption" style={{ fontSize: '0.75rem' }}>
                    {dashboardLink.title}
                  </Typography>
                </RouterLink>
              ) : (
                <ExternalLink href={dashboardLink.url}>
                  {dashboardLink.icon}
                  <br />
                  <Typography variant="caption" style={{ fontSize: '0.75rem' }}>
                    {dashboardLink.title}
                  </Typography>
                </ExternalLink>
              )}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
